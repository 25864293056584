.Block5__Container{
    position: absolute;
    top: 640px;
    left: 304px;
    width: 155px;
    height: 145px;
    box-sizing: border-box;
    border: 1px solid black;
    background-color: rgb(198, 224, 177);
}

/*73 */

.Block5__Part1-Container{
    height: 73px;
    width: 153px;
    display: flex;
}

.Block5__Part1-LeftSide{
    width: 65px;
    height: 73px;
}

.Block5__Part1-LeftSide-UP{
    padding-top: 2px;
    padding-left: 2px;
    font-size: 13px;
    height: 42px;
    background-color: rgb(201,86, 19);
    color: white;
    font-weight: bold;
}

.Block5__Part1-LeftSide-Down{
    height: 24px;
    font-weight: bold;
    color: white;
    padding-top: 5px;
    padding-left: 2px;
    background-color: rgb(255, 192, 0);
}

.Block5__Part1-RightSide{
    width: 88px;
    height: 73px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Block5__Part1-RightSide-Top{
    padding-top: 2px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.Block5__Part1-RightSide-Center{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: rgb(39, 43, 72);
}

.Block5__Part1-RightSide-Bottom{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.Block5__Part2-Container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    border-top: 1px solid black;
    height: 70px;
}

.Block5__Part2-Top{
    font-size: 12px;
    text-align: right;
    font-weight: bold;
    padding-right: 3px;
    padding-top: 3px;
}

.Block5__Part2-Center{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: rgb(39, 43, 72);
}

.Block5__Part2-Bottom{
    font-weight: bold;
    text-align: right;
    font-size: 12px;
    padding-right: 2px;
}

.Block5__ArrowUp{
    position: absolute;
    width: 10px;
    height: 0;
    left: 70px;
    top: -7px;
    border: 1px solid #000000;
    transform: rotate(90deg);
}

.Block5__ArrowLeft1{
    position: absolute;
    width: 8px;
    height: 0;
    left: -11px;
    top: -1px;
    border: 1px solid #000000;
}

.Block5__ArrowLeft2{
    position: absolute;
    width: 8px;
    height: 0;
    left: -11px;
    top: 43px;
    border: 1px solid #000000;
}