.Block11__Container{
    height: 215px;
    width: 100px;
    background-color: rgb(252, 228, 216);
    left: 1122px;
    top: 426px;
    position: absolute;
    border: 1px solid black;
    border-top: none;
    box-sizing: border-box;
}

.Block11__Top{
    height: 13px;
    background-color: rgb(6, 173, 241);
}

.Block11__ALetter{
    height: 45px;
    color: darkgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
}

.Block11__Dualed{
    height: 45px;
    font-size: 32px;
    font-weight: bold;
    display: flex;
}

.Block11__Dualed1{
    width: calc(50% - 2px);
    border-right: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Block11__Dualed2{
    width: calc(50% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Block11__String{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.Block11__SecondDualed{
    margin-top: 1px;
    display: flex;
    height: 90px;
    font-weight: bold;
}

.Block11__SecondDualed1{
    width: calc(50% - 1px);
    background-color: rgb(144, 210, 78);
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Block11__SecondDualed2{
    background-color: rgb(254, 254, 158);
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Block11__SecondDualed1 p{
    font-size: 32px;
}

.Block11__SecondDualed2 p{
    font-size: 32px;
}