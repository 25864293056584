.Block9__String2-Container{
    background-color: #fff;
    display: flex;
    height: 71px;
}

.Block9__String2-Part1{
    display: flex;
    height: 70px;
    width: 175px;
    background-color: rgb(252, 227, 220);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String2-Part1-Header{
    width: 97px;
}

.Block9__String2-Part1-CenterText{
    color: rgb(38, 101, 142)
}

.Block9__String2-Part2{
    display: flex;
    height: 70px;
    width: 155px;
    background-color: rgb(252, 227, 220);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String2-Part2-Header{
    width: 76px;
}

.Block9__String2-Part2-CenterText{
    color: rgb(105, 121, 74);
}

.Block9__String2-Part3{
    display: flex;
    height: 70px;
    width: 175px;
    background-color: rgb(252, 227, 220);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String2-Part3-CenterText{
    color: rgb(58, 165, 201);
}