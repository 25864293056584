.InfoBlock__Container{
    width: 420px;
    height: 180px;
    box-sizing: border-box;
    position: absolute;
    right: 20px;
    top: 110px;
    border: 1px solid black;
    background-color: rgb(198, 224, 177);
}

.InfoBlock__Header{
    display: flex;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid black;
}

.InfoBlock__Header div{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
}

.InfoBlock__Header-Part1{
    height: 20px;
    border: 1px solid black;
    border-top: none;
    box-sizing: border-box;
    width: 190px;
    margin-left: 40px;
    background-color: rgb(255,133,135);
}

.InfoBlock__Header-Part2{
    height: 20px;
    box-sizing: border-box;
    border: 1px solid black;
    border-top: none;
    border-left: none;
    width: 115px;
    background-color: white;
}

.InfoBlock__Header-Part3{
    width: 105px;
    height: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid black;
}

.InfoBlock__Center{
    height: 135px;
    position: relative;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InfoBlock__Center p{
    color: red;
}

.InfoBlock__Center-UpBlock{
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 35px;
    width: 190px;
    border: 1px solid black;
    border-top: none;
    border-left: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    background-color: rgb(255, 192, 0);
}

.InfoBlock__Center-BottomBlock{
    position: absolute;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    width: 190px;
    height: 35px;
    border: 1px solid black;
    border-bottom: none;
    border-left: none;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.InfoBlock__Center-Text1{
    font-size: 14px;
    position: absolute;
    top: 35px;
    right: 20px;
}

.InfoBlock__Center-Text2{
    width: 95px;
    text-align: center;
    position: absolute;
    top: 65px;
    right: 20px;
    font-size: 14px;
}

.InfoBlock__Bottom{
    border-top: 1px solid black;
    display: flex;
    font-size: 12px;
    height: 22px;
    background-color: #fff;
    box-sizing: border-box;
    font-weight: bold;
}

.InfoBlock__Bottom div{
    display: flex;
    align-items: center;
}

.InfoBlock__Bottom-Part1{
    height: 100%;
    width: 170px;
    border-right: 1px solid black;
    padding-left: 5px;
}

.InfoBlock__Bottom-Part2{
    width: 77px;
    height: 100%;
    border-right: 1px solid black;
}

.InfoBlock__Bottom-Part3{
    justify-content: center;
    width: 165px;
    background-color: rgb(255, 192, 0);
}