.Block9__String3-Container{
    background-color: #fff;
    display: flex;
    height: 71px;
}

.Block9__String3-Part1{
    display: flex;
    height: 70px;
    width: 175px;
    background-color: rgb(252, 227, 220);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String3-Part2{
    display: flex;
    height: 70px;
    width: 155px;
    background-color: rgb(252, 227, 220);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String3-Part3{
    display: flex;
    height: 70px;
    width: 175px;
    background-color: rgb(252, 227, 220);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}