.Block13__String3-Container{
    width: 100%;
    height: 75px;
    display: flex;
}

.Block13__String3-Part1{
    height: 100%;
    width: 125px;
    display: flex;
}

.Block13__String3-Part1-Left{
    height: 100%;
    width: 25px;
    background-color: rgb(129,61,12);
}

.Block13__String3-Part1-Right{
    height: 100%;
    position: relative;
    width: 100px;
    box-sizing: border-box;
    border: 1px solid black;
    background: rgb(252, 227, 220);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    border-top: none;
}

.Block13__String3-Part1-Right-BottomBlock{
    width: 35px;
    height: 15px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgb(129,61,12);;
}

.Block13__String3-Part2{
    width: 90px;
    height: 100%;
    border: 1px solid black;
    background: rgb(252, 227, 220);
    color: rgb(204,1, 205);
    border-top: none;
    border-left: none;
    box-sizing: border-box;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.Block13__String3-Part3{
    width: 109px;
    background-color: rgb(204,1, 205);
    height: 100%;
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-left: none;
    border-top: none;
    box-sizing: border-box;
}

.Block13__String3-Part4{
    background: white;
    width: 116px;
    height: 100%;
    border: 1px solid black;
    border-top: none;
    border-left: none;
    box-sizing: border-box;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}