.Block13__String2-Container{
    width: 100%;
    height: 75px;
    margin-top: 15px;
    display: flex;
}

.Block13__String2-Part1{
    height: 100%;
    width: 125px;
    display: flex;
}

.Block13__String2-Part1-Left{
    height: 100%;
    width: 25px;
    background-color: black;
}

.Block13__String2-Part1-Right{
    height: 100%;
    width: 100px;
    box-sizing: border-box;
    border: 1px solid black;
    background: rgb(252, 227, 220);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
}

.Block13__String2-Part2{
    width: 90px;
    height: 100%;
    border: 1px solid black;
    border-left: none;
    box-sizing: border-box;
    background-color: rgb(204,1, 205);
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.Block13__String2-Part3{
    width: 109px;
    height: 100%;
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-left: none;
    box-sizing: border-box;
}

.Block13__String2-Part4{
    width: 116px;
    height: 100%;
    border: 1px solid black;
    border-left: none;
    box-sizing: border-box;
    position: relative;
    background-color: white;
}
.Block13__String2-Part4-Main{
    height: 55px;
    padding-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
}

.Block13__String2-Part4-Bottom{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 15px;
    width: 35px;
    background: black;
}