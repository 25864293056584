.Block9__String5-Container{
    background-color: #fff;
    display: flex;
    height: 69px;
}

.Block9__String5-Part1{
    display: flex;
    height: 68px;
    width: 175px;
    background-color: rgb(200, 223, 179);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String5-Part2{
    display: flex;
    height: 68px;
    width: 155px;
    background-color: rgb(200, 223, 179);
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String5-Part3{
    height: 68px;
    width: 175px;
    background-color: white;
    border: 1px solid black;
    border-left: none;
    border-top: none;
}

.Block9__String5-Part3-Header{
    padding-top: 3px;
    height: 15px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}

.Block9__String5-Part3-Center{
    height: 38px;
    font-size: 32px;
    font-weight: bold;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(151, 166, 195);
}

.Block9__String5-Part3-Bottom{
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    padding-right: 5px;
}