.Block13__String5-Container{
    width: 100%;
    height: 75px;
    display: flex;
}

.Block13__String5-Part1{
    height: 100%;
    width: 125px;
    display: flex;
}

.Block13__String5-Part1-Left{
    height: 100%;
    width: 25px;
    background-color: rgb(255,192, 3);
}

.Block13__String5-Part1-Right{
    height: 100%;
    position: relative;
    width: 100px;
    box-sizing: border-box;
    border: 1px solid black;
    background: rgb(198, 224, 177);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    border-top: none;
}

.Block13__String5-Part2{
    width: 90px;
    height: 100%;
    border: 1px solid black;
    background: rgb(252, 227, 220);
    color: rgb(204,1, 205);
    border-top: none;
    border-left: none;
    box-sizing: border-box;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.Block13__String5-Part2 p{
    display: inline-block;
    color: red;
}

.Block13__String5-Part3{
    width: 109px;
    background: white;
    height: 100%;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-left: none;
    border-top: none;
    box-sizing: border-box;
}

.Block13__String5-Part3-BottomBlock{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 15px;
    width: 35px;
    background: rgb(255,192, 3);
}